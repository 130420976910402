/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import {
    ProductGalleryBaseImageContainer as SourceContainer,
} from 'SourceComponent/ProductGalleryBaseImage/ProductGalleryBaseImage.container';
import media, { PRODUCT_MEDIA } from 'Util/Media/Media';

/** @namespace Satisfly/Component/ProductGalleryBaseImage/Container */
export class ProductGalleryBaseImageContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        alt: PropTypes.string,
    };

    static defaultProps = {
        alt: '',
    };

    shouldComponentUpdate(nextProps) {
        const { scale, mediaData: { id }, isZoomEnabled } = this.props;
        const { scale: nextScale, mediaData: { id: nextId }, isZoomEnabled: nextIsZoomEnabled } = nextProps;

        if (scale !== nextScale || id !== nextId || isZoomEnabled !== nextIsZoomEnabled) {
            return true;
        }

        return false;
    }

    _getSrc() {
        const {
            mediaData: { file, base: { url: baseUrl } = {} },
        } = this.props;

        return file ? media(file, PRODUCT_MEDIA) : baseUrl;
    }

    containerProps() {
        const { alt } = this.props;

        return {
            alt,
            src: this._getSrc(),
        };
    }
}

export default withRouter(
    ProductGalleryBaseImageContainer
);
