/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    draggable: false,
    prevArrow: (
         <div
           block="ProductList"
           elem="SliderArrow"
           mods={ { Left: true } }
         />
    ),
    nextArrow: (
         <div
           block="ProductList"
           elem="SliderArrow"
           mods={ { Right: true } }
         />
    ),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true,
                draggable: false,
            },
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
                draggable: false,
            },
        },
    ],
};
