/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    TranslateOnCursorMoveComponent as SourceTranslateOnCursorMove,
} from 'SourceComponent/TranslateOnCursorMove/TranslateOnCursorMove.component';
import CSS from 'Util/CSS';

/** @namespace Satisfly/Component/TranslateOnCursorMove/Component */
export class TranslateOnCursorMoveComponent extends SourceTranslateOnCursorMove {
    handleLoad() {
        const {
            activeImageId,
            itemSelector,
            targetSelector,
            isMobile,
        } = this.props;

        if (isMobile) {
            return;
        }

        const targets = this.ref.current.querySelectorAll(itemSelector);
        const target = targets?.[activeImageId]?.querySelector(targetSelector);

        if (!target) {
            return;
        }

        const innerHeight = target.getBoundingClientRect().height;
        const { height: wrapperHeight } = this.ref.current.getBoundingClientRect() || {};
        const translate = (wrapperHeight - innerHeight) / 2;

        target.style.transform = `translateY(${translate}px)`;
        CSS.setVariable(this.ref, 'imageOpacity', '1');
    }
}

export default TranslateOnCursorMoveComponent;
