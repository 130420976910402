/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPageContainer as SourceContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { scrollToTop } from 'Util/Browser';

import ProductPage from './ProductPage.component';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace Satisfly/Route/ProductPage/Container */
export class ProductPageContainer extends SourceContainer {
    componentDidMount() {
        const { isMobile } = this.props;

        /**
         * Always request product information. In this case we will have updated data.
         * Service worker will return previous information and updated new information
         * through broadcast.
         */
        this.requestProduct();

        /**
         * Always make sure the navigation switches into the MENU tab
         * */
        this.updateNavigationState();

        /**
         * Ensure transition PDP => homepage => PDP always having proper meta
         */
        this.updateMeta();

        /**
         * Make sure to update header state, the data-source will
         * define the correct information to use for update
         * (it can be a product, history state product or an empty object).
         */
        this.updateHeaderState();
        this.updateBreadcrumbs();

        if (isMobile) {
            document.documentElement.classList.add('temporary');
            document.documentElement.classList.add('headerFixed');
            document.documentElement.classList.add('showTopMenu');

            setTimeout(() => {
                window.scrollBy({ top: 100 });
            }, 0);
            setTimeout(() => {
                document.documentElement.classList.remove('temporary');
                document.documentElement.classList.add('headerAnimate');
            }, 400);
        } else {
            scrollToTop();
        }
    }

    componentWillUnmount() {
        const { isMobile } = this.props;

        if (isMobile) {
            document.documentElement.classList.remove('headerAnimate');
        }
    }

    requestProduct() {
        const { requestProduct, productSKU, productID } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU || productID === 0 || !productID) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const options = {
            isSingleProduct: true,
            args: { filter: this.getProductRequestFilter() },
        };

        requestProduct(options);
    }

    containerProps() {
        const {
            isMobile, location, areReviewsEnabled, store,
        } = this.props;
        const { parameters } = this.state;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            store,
        };
    }

    _addToRecentlyViewedProducts() {
        const {
            product,
            product: { sku },
            addRecentlyViewedProduct,
            store,
        } = this.props;

        // necessary for skipping not loaded products
        if (!sku) {
            return;
        }

        // push into localstorage only preview of product (image, name and etc)
        const {
            canonical_url,
            categories,
            configurable_options,
            description,
            items,
            meta_description,
            meta_keyword,
            meta_title,
            options,
            product_links,
            reviews,
            short_description,
            variants,
            ...productPreview
        } = product;
        const productWithVariant = {
            ...productPreview,
            variants: variants?.length > 0 ? [{ sku: variants[0]?.sku }] : [],
        };

        addRecentlyViewedProduct(productWithVariant, store);
    }

    render() {
        const { isMobile } = this.props;

        return (
            <>
                <ProductPage
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
                { isMobile && (
                     <Footer isVisibleOnMobile />
                ) }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
