/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-dupe-class-members */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import Slider from 'react-slick';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import CarouselScroll from 'Component/CarouselScroll';
import Image from 'Component/Image';
import Popup from 'Component/Popup';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import {
    IMAGE_TYPE,
    MAX_ZOOM_SCALE,
    PLACEHOLDER_TYPE,
    VIDEO_TYPE,
} from 'Component/ProductGalleryDesktop/ProductGalleryDesktop.config';
import ProductGalleryThumbnailImage from 'Component/ProductGalleryThumbnailImage';
import VideoThumbnail from 'Component/VideoThumbnail';

import {
    GALLERY_POPUP_ID,
    sliderSettings,
    START_INDEX,
} from './ProductGalleryPopup.config';

import './ProductGalleryPopup.style';

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Satisfly/Component/ProductGalleryPopup/Component */
export class ProductGalleryPopupComponent extends PureComponent {
    static propTypes = {
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                image: PropTypes.string,
                isPlaceholder: PropTypes.bool,
                alt: PropTypes.string,
                type: PropTypes.string,
                media_type: PropTypes.string,
            })
        ).isRequired,
        activeImage: PropTypes.number.isRequired,
        disableZoom: PropTypes.func.isRequired,
        isZoomEnabled: PropTypes.bool.isRequired,
        handleZoomChange: PropTypes.func.isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        isImageZoomPopupActive: PropTypes.bool.isRequired,
    };

    onWheel = this.onWheel.bind(this);

    sliderRef = createRef();

    state = {
        scrollEnabled: true,
    };

    componentDidMount() {
        const { activeImage } = this.props;

        if (this.sliderRef?.current && activeImage !== START_INDEX) {
            this.sliderRef.current.slickGoTo(activeImage, true);
        }
    }

    componentDidUpdate(prevProps) {
        const { activeImage } = this.props;
        const { activeImage: prevActiveImage } = prevProps;

        if (this.sliderRef?.current && activeImage !== prevActiveImage) {
            this.sliderRef.current.slickGoTo(activeImage, true);
        }
    }

    onWheel(zoomState) {
        const { scale } = zoomState;

        if (this.timeout) {
            return;
        }

        if (scale === 1 || scale === MAX_ZOOM_SCALE) {
            this.stopScrolling();
        }
    }

    stopScrolling() {
        this.setState({ scrollEnabled: false });
        this.timeout = setTimeout(() => {
            this.setState({ scrollEnabled: true });
            this.timeout = null;

            // 20 ms is time give to scroll down, usually that is enough
            // eslint-disable-next-line no-magic-numbers
        }, 20);
    }

    renderImage(mediaData, index) {
        const {
            handleZoomChange,
            isZoomEnabled,
            disableZoom,
        } = this.props;
        const { scrollEnabled } = this.state;

        return (
            <div block="ProductGalleryPopup" elem="ImageWrapper" key={ index }>
                <TransformWrapper
                  onZoomChange={ handleZoomChange }
                  onWheelStart={ this.onWheelStart }
                  wheel={ {
                      limitsOnWheel: true,
                      disabled: !scrollEnabled,
                      step: 60,
                  } }
                  pan={ {
                      disabled: !isZoomEnabled,
                      limitToWrapperBounds: true,
                      velocity: false,
                  } }
                  options={ {
                      limitToBounds: true,
                      minScale: 1,
                  } }
                >
                    { ({
                        scale,
                        previousScale,
                        resetTransform,
                        setTransform,
                    }) => {
                        if (scale === 1 && previousScale !== 1) {
                            resetTransform();
                        }

                        return (
                            <ProductGalleryBaseImage
                              setTransform={ setTransform }
                              index={ index }
                              mediaData={ mediaData }
                              scale={ scale }
                              previousScale={ previousScale }
                              disableZoom={ disableZoom }
                              isZoomEnabled={ isZoomEnabled }
                            />
                        );
                    } }
                </TransformWrapper>
            </div>
        );
    }

    renderVideo(media, index) {
        const { activeImage } = this.props;

        return (
            <VideoThumbnail
              key={ index }
              media={ media }
              isVideoZoomed={ false }
              onZoomedVideoClick={ () => null }
              isPopup
              activeImage={ activeImage }
              itemIndex={ index }
            />
        );
    }

    renderPlaceholder(index) {
        return (
            <Image
              key={ index }
              ratio="custom"
              mix={ {
                  block: 'ProductGallery',
                  elem: 'SliderImage',
                  mods: { isPlaceholder: true },
              } }
              isPlaceholder
            />
        );
    }

    renderSlide(media, index) {
        const { media_type } = media;

        switch (media_type) {
        case IMAGE_TYPE:
            return this.renderImage(media, index);
        case VIDEO_TYPE:
            return this.renderVideo(media, index);
        case PLACEHOLDER_TYPE:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderSlides() {
        const { gallery } = this.props;

        return gallery.map((item, index) => (
            this.renderSlide(item, index)
        ));
    }

    handleBeforeChange = (_oldIndex, newIndex) => {
        const { onActiveImageChange } = this.props;
        onActiveImageChange(newIndex);
    };

    renderSlider() {
        return (
            <div block="ProductGalleryPopup" elem="SliderWrapper">
                <Slider
                  { ...sliderSettings }
                  ref={ this.sliderRef }
                  beforeChange={ this.handleBeforeChange }
                >
                    { this.renderSlides() }
                </Slider>
            </div>
        );
    }

    renderAdditionalPicture(media, index = 0) {
        return (
            <ProductGalleryThumbnailImage
              key={ index }
              media={ media }
            />
        );
    }

    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange,
        } = this.props;

        if (gallery.length > 1) {
            return (
                <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                    <CarouselScroll
                      activeItemId={ activeImage }
                      onChange={ onActiveImageChange }
                      showedItemCount={ gallery.length }
                      isImageZoomPopupActive={ isImageZoomPopupActive }
                    >
                        { gallery.map(this.renderAdditionalPicture.bind(this)) }
                    </CarouselScroll>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <Popup id={ GALLERY_POPUP_ID } mix={ { block: 'ProductGalleryPopup' } } preventGoBack>
                <div block="ProductGalleryPopup" elem="Gallery">
                    { this.renderSlider() }
                    { this.renderAdditionalPictures() }
                </div>
            </Popup>
        );
    }
}

export default ProductGalleryPopupComponent;
