/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    VideoThumbnailContainer as SourceContainer,
} from 'SourceComponent/VideoThumbnail/VideoThumbnail.container';

import VideoThumbnail from './VideoThumbnail.component';

/** @namespace Satisfly/Component/VideoThumbnail/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

export { mapDispatchToProps };

/**
 * @class VideoThumbnailContainer
 * @namespace Satisfly/Component/VideoThumbnail/Container */
export class VideoThumbnailContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        isPopup: PropTypes.bool.isRequired,
        activeImage: PropTypes.number,
        itemIndex: PropTypes.number,
        isMobile: PropTypes.bool.isRequired,
        isMainGallery: PropTypes.bool,
    };

    static defaultProps = {
        isPopup: false,
        itemIndex: 0,
        activeImage: 0,
        isMainGallery: false,
    };

    videoRef = createRef();

    componentDidUpdate(prevProps) {
        const { activeImage, isMobile } = this.props;
        const { activeImage: prevActiveImage } = prevProps;

        if ((activeImage + 1 === prevActiveImage || activeImage - 1 === prevActiveImage) && isMobile) {
            const video = this.videoRef?.current;

            if (video) {
                video.loop = false;
                video.pause();
                document.querySelector('.VideoThumbnail-PlayIconWrapper').style.display = 'block';
                document.querySelector('.VideoThumbnail-PlayIcon').style.display = 'block';
                document.querySelector('.VideoThumbnail-PauseIcon').style.display = 'none';
            }
        }
    }

    onPlayClick() {
        const { isMobile } = this.props;

        if (isMobile && this.videoRef?.current) {
            this.handlePlay();
        }
    }

    handlePlay = () => {
        const { isMainGallery, isMobile } = this.props;
        const video = this.videoRef?.current;

        if (video.paused) {
            document.querySelector('.VideoThumbnail-PlayIcon').style.display = 'none';

            if (isMobile) {
                document.querySelector('.VideoThumbnail-PlayIconWrapper').style.display = 'none';
                video.loop = true;
            }

            if (isMainGallery || isMobile) {
                document.querySelector('.VideoThumbnail-PauseIcon').style.display = 'block';
            }

            video.play();
        } else {
            document.querySelector('.VideoThumbnail-PlayIcon').style.display = 'block';

            if (isMobile) {
                document.querySelector('.VideoThumbnail-PlayIconWrapper').style.display = 'block';
                video.loop = false;
            }

            if (isMainGallery || isMobile) {
                document.querySelector('.VideoThumbnail-PauseIcon').style.display = 'none';
            }

            video.pause();
        }
    };

    handleClickPlayIcon = (e) => {
        const { isMainGallery, isMobile } = this.props;

        if (isMainGallery || isMobile) {
            e.stopPropagation();
            e.preventDefault();
            this.handlePlay();
        }
    };

    containerProps() {
        const {
            media,
            isVideoZoomed,
            isPopup,
            activeImage,
            itemIndex,
            isMobile,
            isMainGallery,
        } = this.props;

        return {
            media,
            isVideoZoomed,
            isPopup,
            activeImage,
            itemIndex,
            isMobile,
            videoRef: this.videoRef,
            isMainGallery,
            handleClickPlayIcon: this.handleClickPlayIcon,
        };
    }

    render() {
        return (
            <VideoThumbnail
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoThumbnailContainer);
