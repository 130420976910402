/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    PopupComponent as SourcePopup,
} from 'SourceComponent/Popup/Popup.component';
import history from 'Util/History';

/** @namespace Satisfly/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    hidePopUp() {
        const { hideActiveOverlay, goToPreviousNavigationState, onClose } = this.props;
        const isVisible = this.getIsVisible();

        window.removeEventListener('popstate', this.hidePopUp);

        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();
        }
    }

    hidePopupAndGoBack() {
        const { preventGoBack } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            if (!preventGoBack) {
                history.goBack();
            }
            this.hidePopUp();
        }
        this.hidePopUp();
    }
}

export default PopupComponent;
