/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Suspense } from 'react';
import { connect } from 'react-redux';

import {
    ImageZoomPopupContainer as SourceImageZoomPopupContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.container';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import ImageZoomPopup from './ImageZoomPopup.component';

export const Popup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-overlays" */
    'Component/Popup'
));

/** @namespace Satisfly/Component/ImageZoomPopup/Container/mapStateToProps */
export { mapStateToProps, mapDispatchToProps };

/** @namespace Satisfly/Component/ImageZoomPopup/Container */
export class ImageZoomPopupContainer extends SourceImageZoomPopupContainer {
    render() {
        const {
            isActive,
            children,
            mix,
            popupId,
            onClose,
        } = this.props;

        if (!isActive) {
            return children;
        }

        return (
            <Suspense fallback={ null }>
                <Popup
                  id={ popupId }
                  clickOutside={ false }
                  mix={ { block: 'ImageZoomPopup', mix } }
                  contentMix={ { block: 'ImageZoomPopup', elem: 'PopupContent' } }
                  onClose={ onClose }
                  onHide={ onClose }
                  isHistoryBackHandle={ false }
                >
                    <ImageZoomPopup
                      { ...this.containerProps() }
                    />
                </Popup>
            </Suspense>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageZoomPopupContainer);
