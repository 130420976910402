/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    VideoPopupComponent as SourceVideoPopup,
} from 'SourceComponent/VideoPopup/VideoPopup.component';

import './VideoPopup.style';

/** @namespace Satisfly/Component/VideoPopup/Component */
export class VideoPopupComponent extends SourceVideoPopup {
    _renderYoutubeVideo(videoId) {
        const YouTube = this.youTubeComponent;

        if (!YouTube) {
            return null;
        }

        return (
            <YouTube
              videoId={ videoId }
              containerClassName="VideoPopup-YouTubeContainer"
              // eslint-disable-next-line react/forbid-component-props
              className="VideoPopup-YouTube"
              opts={ { playerVars: { autoplay: 1, rel: 0 } } }
            />
        );
    }
}

export default VideoPopupComponent;
