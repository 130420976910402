/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// controls CSS animation speed
export const ANIMATION_DURATION = 400;
export const NOTIFICATION_LIFETIME = 1500;
export const ERROR_NOTIFICATION_LIFETIME = 2500;
export const ERROR_TYPE = 'error';
export const WARN_TYPE = 'warn';
export const INFO_TYPE = 'info';
export const SUCCESS_TYPE = 'success';
