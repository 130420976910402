/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const PRODUCT_INFORMATION = 'information';
export const PRODUCT_ATTRIBUTES = 'attributes';
export const PRODUCT_REVIEWS = 'reviews';
export const PRODUCT_REVIEWS_WIDGET = 'reviews-widget';
export const PRODUCT_MATERIALS = 'materials';
export const PRODUCT_DELIVERY = 'delivery';
export const PRODUCT_KEY_FEATURES = 'key-features';
export const ADDITIONALS_FEATURES = 'additional_features';
export const SLIDER_OFFSET = 200;
export const DELAY_STICKY = 500;
export const HEADER_HEIGHT = 160;
export enum ProductPageTabs {
    INFORMATION = 'information',
    ATTRIBUTES = 'attributes',
    REVIEWS = 'reviews',
}
