/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import Image from 'Component/Image';
import {
    THUMBNAIL_KEY,
} from 'Component/ProductGallery/ProductGallery.config';
import {
    ProductGalleryThumbnailImageComponent as SourceComponent,
} from 'SourceComponent/ProductGalleryThumbnailImage/ProductGalleryThumbnailImage.component';
import media, { PRODUCT_MEDIA } from 'Util/Media';

import './ProductGalleryThumbnailImage.style';

/** @namespace Satisfly/Component/ProductGalleryThumbnailImage/Component */
export class ProductGalleryThumbnailImageComponent extends SourceComponent {
    static propTypes = {
        ...super.propTypes,
        alt: PropTypes.string,
    };

    static defaultProps = {
        alt: '',
    };

    renderVideo() {
        const {
            media: {
                video_content,
            },
        } = this.props;

        return (
            <video muted block="VideoPlayer" elem="Video">
                <source
                  src={ video_content.video_url }
                  type="video/mp4"
                />
                <source
                  src={ video_content.video_url }
                  type="video/webm"
                />
                <source
                  src={ video_content.video_url }
                  type="video/avi"
                />
                <source
                  src={ video_content.video_url }
                  type="video/wmv"
                />
                <source
                  src={ video_content.video_url }
                  type="video/mov"
                />
            </video>
        );
    }

    renderImage() {
        const {
            media: {
                file,
                thumbnail: { url: thumbnailUrl } = {},
                id,
            },
            alt,
        } = this.props;

        if (id === THUMBNAIL_KEY) {
            return this.renderPlaceholder();
        }

        const src = thumbnailUrl || media(file, PRODUCT_MEDIA);

        return (
            <Image
              src={ src }
              alt={ alt }
              ratio="custom"
              mix={ { block: 'ProductGalleryThumbnailImage' } }
            />
        );
    }
}

export default ProductGalleryThumbnailImageComponent;
