/* eslint-disable fp/no-let */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { GALLERY_POPUP_ID } from 'Component/ProductGalleryPopup/ProductGalleryPopup.config';
import { VIMEO_FORMAT, YOUTUBE_FORMAT } from 'Component/VideoThumbnail/VideoThumbnail.config';
import {
    ProductGalleryContainer as SourceContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import ProductGalleryDesktop from './ProductGalleryDesktop.component';
import {
    AMOUNT_OF_PLACEHOLDERS,
    IMAGE_TYPE,
    THUMBNAIL_KEY,
    VIDEO_TYPE,
} from './ProductGalleryDesktop.config';

/** @namespace Satisfly/Component/ProductGalleryDesktop/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    activeOverlay: state.OverlayReducer.activeOverlay,
});

/** @namespace Satisfly/Component/ProductGalleryDesktop/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

/** @namespace Satisfly/Component/ProductGalleryDesktop/Container */
export class ProductGalleryDesktopContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        activeOverlay: PropTypes.string.isRequired,
        showOverlay: PropTypes.func.isRequired,
    };

    handleOpenPopup = (mediaType, activeImage) => {
        const { showOverlay } = this.props;

        if (mediaType === IMAGE_TYPE || mediaType === VIDEO_TYPE) {
            showOverlay(GALLERY_POPUP_ID);
            this.setState({
                activeImage,
            });
        }
    };

    getGalleryPictures() {
        const {
            areDetailsLoaded,
            product: {
                media_gallery_entries: mediaGallery = [],
                [THUMBNAIL_KEY]: { url: thumbnailUrl } = {},
                [IMAGE_TYPE]: { url: imageTypeUrl } = {},
                name,
                attributes: { url_video = null } = {},
            },
        } = this.props;

        const url = imageTypeUrl || thumbnailUrl;

        let finallGallery = mediaGallery;

        if (finallGallery.length) {
            finallGallery = mediaGallery
                .filter(({ disabled, media_type }) => !disabled && media_type !== VIDEO_TYPE)
                .sort((a, b) => a.position - b.position);
        }

        const [, vimeoId] = VIMEO_FORMAT.exec(url_video?.attribute_value || '') || [];
        const [, youtubeId] = YOUTUBE_FORMAT.exec(url_video?.attribute_value || '') || [];

        if (url_video?.attribute_value && !vimeoId && !youtubeId) {
            const tempGallery = finallGallery;

            finallGallery = [
                finallGallery?.[0],
                {
                    id: url_video.attribute_id,
                    file: '',
                    label: null,
                    position: finallGallery.length + 1,
                    disabled: false,
                    media_type: 'external-video',
                    types: [],
                    video_content: {
                        media_type: 'external-video',
                        video_description: 'Video.',
                        video_metadata: '',
                        video_provider: '',
                        video_title: '',
                        video_url: url_video?.attribute_value,
                    },
                    thumbnail: {
                        url: url_video?.attribute_value,
                    },
                    base: {
                        url: url_video?.attribute_value,
                    },
                    large: {
                        url: url_video?.attribute_value,
                    },
                },
                ...tempGallery.slice(1),
            ];
        }

        if (finallGallery.length) {
            return finallGallery;
        }

        if (!url) {
            return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
        }

        const placeholders = !areDetailsLoaded
            ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

        return [
            {
                thumbnail: { url },
                base: { url },
                id: THUMBNAIL_KEY,
                label: name,
                media_type: IMAGE_TYPE,
            },
            ...placeholders,
        ];
    }

    containerProps() {
        const { activeImage, isZoomEnabled, isImageZoomPopupActive } = this.state;
        const {
            product: { id, attributes: { url_video = null } = {} },
            isMobile,
            showLoader,
            activeOverlay,
            isWithEmptySwitcher,
        } = this.props;

        return {
            gallery: this.getGalleryPictures(),
            productName: this._getProductName(),
            activeImage,
            isZoomEnabled,
            productId: id,
            isMobile,
            isImageZoomPopupActive,
            sliderRef: this.sliderRef,
            showLoader,
            activeOverlay,
            handleOpenPopup: this.handleOpenPopup,
            url_video,
            isWithEmptySwitcher,
        };
    }

    render() {
        return (
            <ProductGalleryDesktop
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryDesktopContainer);
