/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { MediaItemType } from 'Type/ProductList.type';
import { makeCancelable } from 'Util/Promise';

import { VIMEO_FORMAT, YOUTUBE_FORMAT } from './VideoPlayer.config';

import './VideoPlayer.style';

/**
 * A popup capable of displaying a video
 * @class VideoPopup
 * @namespace Satisfly/Component/VideoPlayer/Component */
export class VideoPlayerComponent extends PureComponent {
    static propTypes = {
        media: MediaItemType.isRequired,
        activeImage: PropTypes.number.isRequired,
        itemIndex: PropTypes.number.isRequired,
    };

    state = {
        loadFlag: false,
    };

    videoRef = createRef();

    componentDidMount() {
        const { itemIndex, activeImage } = this.props;

        this.loadVimeoLibrary();
        this.loadYouTubeLibrary();

        const video = this.videoRef?.current;

        if (video && itemIndex === activeImage) {
            this.handlePlay();
            document.querySelector('.VideoPlayer-PlayIcon').style.display = 'none';
            video.addEventListener('timeupdate', () => {
                // eslint-disable-next-line no-magic-numbers
                const curr = (video.currentTime / video.duration) * 100;
                const inner = document.querySelector('.VideoPlayer-Inner');

                if (video.ended) {
                    document.querySelector('.VideoPlayer-PlayIcon').style.display = 'block';
                }

                if (inner) {
                    inner.style.width = `${curr}%`;
                }
            });
        }
    }

    componentWillUnmount() {
        if (this.youTubePromise) {
            this.youTubePromise.cancel();
        }

        if (this.vimeoPromise) {
            this.vimeoPromise.cancel();
        }
    }

    /**
     * Renders a video provided by Vimeo
     * @param videoId
     * @returns {*}
     * @private
     */
    _renderVimeoVideo(videoId) {
        const Vimeo = this.vimeoComponent;

        if (!Vimeo) {
            return null;
        }

        return (
            <Vimeo
              videoId={ videoId }
              autoplay
            />
        );
    }

    /**
     * Renders a video provided by Youtube
     * @param videoId
     * @returns {*}
     * @private
     */
    _renderYoutubeVideo(videoId) {
        const YouTube = this.youTubeComponent;

        if (!YouTube) {
            return null;
        }

        return (
            <YouTube
              videoId={ videoId }
              containerClassName="VideoPopup-YouTubeContainer"
              // eslint-disable-next-line react/forbid-component-props
              className="VideoPopup-YouTube"
              opts={ { playerVars: { autoplay: 1 } } }
            />
        );
    }

    loadVimeoLibrary() {
        this.vimeoPromise = makeCancelable(import('react-vimeo'));

        this.vimeoPromise.promise.then(
            /** @namespace Satisfly/Component/VideoPlayer/Component/VideoPlayerComponent/loadVimeoLibrary/then */
            ({ default: vimeo }) => {
                this.vimeoComponent = vimeo;
                this.setState((prevState) => ({
                    loadFlag: !prevState.loadFlag,
                }));
            }
        );
    }

    loadYouTubeLibrary() {
        this.youTubePromise = makeCancelable(import('react-youtube'));

        this.youTubePromise.promise.then(
            /** @namespace Satisfly/Component/VideoPlayer/Component/VideoPlayerComponent/loadYouTubeLibrary/then */
            ({ default: youTube }) => {
                this.youTubeComponent = youTube;
                this.setState((prevState) => ({
                    loadFlag: !prevState.loadFlag,
                }));
            }
        );
    }

    handlePlay = () => {
        const video = this.videoRef?.current;

        if (video.paused) {
            document.querySelector('.VideoPlayer-PlayIcon').style.display = 'none';
            video.play();
        } else {
            document.querySelector('.VideoPlayer-PlayIcon').style.display = 'block';
            video.pause();
        }
    };

    _renderCustomPlayer() {
        const {
            media: {
                video_content: { video_url } = {},
            } = {},
        } = this.props;

        return (
            <div block="VideoPlayer" elem="CustomPlayer">
                <div block="VideoPlayer" elem="Container">
                    <video block="VideoPlayer" elem="Video" ref={ this.videoRef } preload="metadata">
                        <source
                          src={ `${video_url}#t=0.001` }
                          type="video/mp4"
                        />
                        <source
                          src={ `${video_url}#t=0.001` }
                          type="video/webm"
                        />
                        <source
                          src={ `${video_url}#t=0.001` }
                          type="video/avi"
                        />
                        <source
                          src={ `${video_url}#t=0.001` }
                          type="video/wmv"
                        />
                        <source
                          src={ `${video_url}#t=0.001` }
                          type="video/mov"
                        />
                    </video>
                    <div block="VideoPlayer" elem="Controls" onClick={ this.handlePlay }>
                        <button
                          block="VideoPlayer"
                          elem="PlayIcon"
                        />
                        <div block="VideoPlayer" elem="Timeline">
                            <div block="VideoPlayer" elem="Bar">
                                <div block="VideoPlayer" elem="Inner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Parses the video URL and renders the video accordingly
     * @returns {null|*}
     * @private
     */
    _renderVideoContent() {
        const {
            media: {
                video_content: { video_url } = {},
            } = {},
        } = this.props;

        if (!video_url) {
            return null;
        }

        const [, vimeoId] = VIMEO_FORMAT.exec(video_url) || [null, null];

        if (vimeoId) {
            return this._renderVimeoVideo(vimeoId);
        }

        const [, youtubeId] = YOUTUBE_FORMAT.exec(video_url) || [null, null];

        if (youtubeId) {
            return this._renderYoutubeVideo(youtubeId);
        }

        return this._renderCustomPlayer();
    }

    render() {
        return (
            <div block="VideoPlayer" elem="VideoPlayer">
                <div block="VideoPlayer" elem="PlayerContent">
                    { this._renderVideoContent() }
                </div>
            </div>
        );
    }
}

export default VideoPlayerComponent;
