Mosaic.addPlugins([require('/var/www/public/app/design/storefront/satisfly/node_modules/@satisfly/product-labels/src/plugin/ProductActions.plugin.js')]);
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { createRef } from 'react';
import { withRouter } from 'react-router';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import {
    ARROW_SAFE_AREA,
    CAROUSEL_ITEM_GAP,
    CAROUSEL_ITEM_WIDTH,
} from 'Component/CarouselScroll/CarouselScroll.config';
import Image from 'Component/Image';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import VideoThumbnail from 'Component/VideoThumbnail';
import { ProductGalleryComponent as SourceProductGallery } from 'SourceComponent/ProductGallery/ProductGallery.component';
import CSS from 'Util/CSS';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import {
    MAX_FILE_NAME_LENGTH, MAX_ZOOM_SCALE, VIDEO_TYPE,
    ZOOM_ANIMATION_TIME, ZOOM_STEP,
} from './ProductGallery.config';

import './ProductGallery.style';

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Satisfly/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    state = {
        scrollEnabled: true,
        slidesCount: 6,
        prevZoom: false,
    };

    imageRef = createRef();

    componentDidMount() {
        this.updateSharedDestinationElement();
        window.addEventListener('resize', this.calculateGallerySize);
    }

    componentDidUpdate(prevProps) {
        const {
            productId,
            location: { pathname = '' } = {},
            sliderRef,
            isImageZoomPopupActive,
        } = this.props;

        const {
            productId: prevProductId,
            location: { pathname: prevPathname = '' } = {},
        } = prevProps;

        const { prevZoom } = this.state;

        if (productId !== prevProductId) {
            this.updateSharedDestinationElement();
        }

        if (sliderRef?.current?.draggableRef && pathname !== prevPathname) {
            CSS.setVariable(
                sliderRef.current.draggableRef,
                'animation-speed',
                0,
            );
        }

        if (isImageZoomPopupActive !== prevZoom) {
            this.handleZoomChange(isImageZoomPopupActive);
        }
    }

    _calculateGallerySize() {
        const ref = this.galleryRef.current;

        if (!ref) {
            return;
        }
        const { width } = ref.getBoundingClientRect();

        const slidesCount = Math.floor((width - ARROW_SAFE_AREA * 2) / (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_GAP));

        this.setState({ slidesCount });
    }

    renderVideo(media, index) {
        const { isImageZoomPopupActive, handleImageZoomPopupActiveChange, activeImage } = this.props;

        return (
            <VideoThumbnail
              key={ index }
              media={ media }
              isVideoZoomed={ isImageZoomPopupActive }
              onZoomedVideoClick={ handleImageZoomPopupActiveChange }
              itemIndex={ index }
              activeImage={ activeImage }
            />
        );
    }

    renderImage(mediaData, index) {
        const {
            isZoomEnabled,
            handleZoomChange,
            disableZoom,
            isImageZoomPopupActive,
            showLoader,
            productName,
        } = this.props;
        const { scrollEnabled } = this.state;
        const { file = '' } = mediaData;
        const alt = `${ productName } ${ file.slice(0, MAX_FILE_NAME_LENGTH) }`;

        if (!isImageZoomPopupActive) {
            const {
                base: { url: baseSrc } = {},
                large: { url: largeSrc } = {},
            } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive ? largeSrc || baseSrc : baseSrc;

            return (
                <Image
                  key={ index }
                  src={ src }
                  ratio="custom"
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src },
                  } }
                  isPlaceholder={ !src }
                  style={ style }
                  showIsLoading={ showLoader }
                  alt={ alt }
                  onImageLoad={ () => {
                      setLoadedFlag();
                      window.isPrefetchValueUsed = false;
                  } }
                />
            );
        }

        return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheelStart={ this.onWheelStart }
              onWheel={ this.onWheel }
              wheel={ { limitsOnWheel: true, disabled: !scrollEnabled, step: ZOOM_STEP } }
              doubleClick={ { mode: 'zoomIn', animationTime: ZOOM_ANIMATION_TIME } }
              pan={ {
                  disabled: !isZoomEnabled,
                  limitToWrapperBounds: true,
                  velocity: false,
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1,
                  maxScale: MAX_ZOOM_SCALE,
              } }
            >
                { ({
                    scale,
                    previousScale,
                    resetTransform,
                    setTransform,
                }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isZoomEnabled }
                          alt={ alt }
                        />
                    );
                } }
            </TransformWrapper>
        );
    }

    updateSharedDestinationElement() {
        const { registerSharedElementDestination } = this.props;
        registerSharedElementDestination(this.imageRef);
    }

    handleSliderClick() {
        const {
            handleImageZoomPopupActiveChange,
            gallery,
            activeImage,
        } = this.props;

        if (activeImage === undefined) {
            return;
        }

        const { media_type } = gallery[activeImage];

        if (media_type === VIDEO_TYPE) {
            return;
        }

        handleImageZoomPopupActiveChange(true);
    }

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCounter={ isMobile && !isImageZoomPopupActive }
                  showArrows={ isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    render() {
        return (
            <div block="ProductGallery" ref={ this.galleryRef }>
                { this.renderSlider() }
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
