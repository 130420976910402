/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { withRouter } from 'react-router';

import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import ProductGalleryPopup from 'Component/ProductGalleryPopup';
import { GALLERY_POPUP_ID } from 'Component/ProductGalleryPopup/ProductGalleryPopup.config';
import VideoThumbnail from 'Component/VideoThumbnail';
import { ProductGalleryComponent as SourceProductGallery } from 'SourceComponent/ProductGallery/ProductGallery.component';
import {
    MediaType,
} from 'SourceComponent/ProductGallery/ProductGallery.config';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import {
    MAX_FILE_NAME_LENGTH,
} from './ProductGalleryDesktop.config';

import './ProductGalleryDesktop.style';

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Satisfly/Component/ProductGalleryDesktop/Component */
export class ProductGalleryDesktopComponent extends SourceProductGallery {
    renderVideo(media, index) {
        const { isImageZoomPopupActive, handleImageZoomPopupActiveChange } = this.props;

        return (
            <div
              block="ProductGalleryDesktop"
              elem="VideoItemWrapper"
            >
                <VideoThumbnail
                  key={ index }
                  media={ media }
                  isVideoZoomed={ isImageZoomPopupActive }
                  onZoomedVideoClick={ handleImageZoomPopupActiveChange }
                  isMainGallery
                />
            </div>
        );
    }

    renderImage(mediaData, index) {
        const {
            showLoader,
            productName,
            isImageZoomPopupActive,
        } = this.props;
        const {
            file = '',
            base: { url: baseSrc } = {},
            large: { url: largeSrc } = {},
        } = mediaData;
        const alt = `${ productName } ${ file.slice(0, MAX_FILE_NAME_LENGTH) }`;
        const src = isImageZoomPopupActive ? largeSrc || baseSrc : baseSrc;

        return (
            <Image
              key={ index }
              src={ src }
              ratio="custom"
              mix={ {
                  block: 'ProductGalleryDesktop',
                  elem: 'GridImage',
                  mods: { isPlaceholder: !src },
              } }
              isPlaceholder={ !baseSrc }
              showIsLoading={ showLoader }
              alt={ alt }
              onImageLoad={ () => {
                  setLoadedFlag();
                  window.isPrefetchValueUsed = false;
              } }
            />
        );
    }

    /**
     * Checks for the type of the slide and renders it accordingly
     * @param media
     * @param index
     * @returns {null|*}
     */
    renderSlide(media, index) {
        const { activeImage } = this.props;
        const { media_type } = media;
        // const { isPrefetchValueUsed = false } = window;

        if (activeImage === undefined) {
            return null;
        }

        // if (index !== activeImage && isPrefetchValueUsed) {
        //     return <div />;
        // }

        switch (media_type) {
        case MediaType.IMAGE:
            return this.renderImage(media, index);
        case MediaType.VIDEO:
            return this.renderVideo(media, index);
        case MediaType.PLACEHOLDER:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderGrid() {
        const {
            gallery,
            handleOpenPopup,
        } = this.props;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGalleryDesktop"
              elem="GridWrapper"
              data-is-pdp
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                { this.renderPlaceholderImage() }
                { gallery.map((media, index) => (
                    <div
                      block="ProductGalleryDesktop"
                      elem="ItemWrapper"
                      mods={ { media: media.media_type } }
                      onClick={ () => handleOpenPopup(media.media_type, index) }
                    >
                        { this.renderSlide(media, index) }
                    </div>
                )) }
            </div>
        );
    }

    renderPlaceholderImage() {
        const { activeImage } = this.props;

        const {
            location: {
                state: {
                    product: { small_image: { url = '' } = {} } = {},
                } = {},
            } = {},
        } = history ?? {};

        return (
            <Image
              src={ url }
              ratio={ ImageRatio.IMG_CUSTOM }
              mix={ {
                  block: 'ProductGalleryDesktop',
                  elem: 'SliderImage',
                  mods: { isHidden: activeImage !== undefined },
                  mix: { elem: 'ItemWrapper' },
              } }
            />
        );
    }

    renderGalleryPopup() {
        const {
            activeOverlay,
            gallery,
            activeImage,
            handleZoomChange,
            isZoomEnabled,
            disableZoom,
            isImageZoomPopupActive,
            onActiveImageChange,
        } = this.props;

        if (activeOverlay !== GALLERY_POPUP_ID) {
            return null;
        }

        return (
            <ProductGalleryPopup
              gallery={ gallery }
              activeImage={ activeImage }
              handleZoomChange={ handleZoomChange }
              isZoomEnabled={ isZoomEnabled }
              disableZoom={ disableZoom }
              isImageZoomPopupActive={ isImageZoomPopupActive }
              onActiveImageChange={ onActiveImageChange }
            />
        );
    }

    render() {
        return (
            <div block="ProductGalleryDesktop" ref={ this.galleryRef }>
                { this.renderGrid() }
                { this.renderGalleryPopup() }
            </div>
        );
    }
}

export default withRouter(ProductGalleryDesktopComponent);
