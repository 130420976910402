/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CAROUSEL_ITEM_GAP } from 'Component/CarouselScroll/CarouselScroll.config';
import {
    CarouselScrollComponent as SourceCarouselScroll,
} from 'SourceComponent/CarouselScroll/CarouselScroll.component';
import CSS from 'Util/CSS';

import './CarouselScroll.style';

/** @namespace Satisfly/Component/CarouselScroll/Component */
export class CarouselScrollComponent extends SourceCarouselScroll {
    componentDidMount() {
        const { showedItemCount, activeItemId } = this.props;

        const margin = CAROUSEL_ITEM_GAP;
        const width = this.getCarouselWidth(showedItemCount);

        CSS.setVariable(this.carouselRef, 'carousel-scroll-gap', `${margin}px`);
        CSS.setVariable(this.carouselRef, 'carousel-width', width);

        if (activeItemId !== null) {
            this.handleChange(activeItemId);
        }
    }

    renderContent() {
        const {
            children,
            isImageZoomPopupActive,
            showArrow,
            showedItemCount,
            children: { length: childrenLength },
        } = this.props;

        return (
            <div block="CarouselScroll" elem="ContentWrapper" mods={ { isImageZoomPopupActive, centered: (!showArrow || childrenLength <= showedItemCount) } }>
                <div block="CarouselScroll" elem="Content">
                    { children.map(this.renderContentItem.bind(this)) }
                </div>
            </div>
        );
    }
}

export default CarouselScrollComponent;
