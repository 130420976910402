/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    ImageZoomPopupComponent as SourceImageZoomPopup,
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Satisfly/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopup {}

export default ImageZoomPopupComponent;
