/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/forbid-component-props */
/* eslint-disable jsx-a11y/alt-text */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import { ReactComponent as WoolIcon } from 'Style/icons/keyFeatures/100_welna.svg';
import { ReactComponent as AntiSlipIcon } from 'Style/icons/keyFeatures/antyposlizgowy-spod.svg';
import CareAndFairIcon from 'Style/icons/keyFeatures/care_and_fair.png';
import { ReactComponent as RecycleIcon } from 'Style/icons/keyFeatures/global_recycled_standard.svg';
import { ReactComponent as EasyCareIcon } from 'Style/icons/keyFeatures/latwy-w-czyszczeniu.svg';
import { ReactComponent as MachineWashableIcon } from 'Style/icons/keyFeatures/mozna-prac.svg';
import { ReactComponent as LowFleeceIcon } from 'Style/icons/keyFeatures/niskie_runo.svg';
import OEKOIcon from 'Style/icons/keyFeatures/Oeko.png';
import { ReactComponent as FloorHeatingIcon } from 'Style/icons/keyFeatures/ogrzewanie_podlogowe.svg';
import { ReactComponent as OutdoorIcon } from 'Style/icons/keyFeatures/outdoor.svg';
import { ReactComponent as EkoIcon } from 'Style/icons/keyFeatures/produkt_zrownowazony.svg';
import { ReactComponent as AnimalIcon } from 'Style/icons/keyFeatures/przyjazne_zwierzetom.svg';
import { ReactComponent as AlergicIcon } from 'Style/icons/keyFeatures/przyjazny_dla_alergikow.svg';
import { ReactComponent as FleeceIcon } from 'Style/icons/keyFeatures/srednie_runo.svg';
import { ReactComponent as MachineIcon } from 'Style/icons/keyFeatures/tkany_maszynowo.svg';
import { ReactComponent as HandIcon } from 'Style/icons/keyFeatures/tkany_recznie.svg';
import { ReactComponent as AbsorbWaterIcon } from 'Style/icons/keyFeatures/wchlania_wode.svg';
import { ReactComponent as HighFleeceIcon } from 'Style/icons/keyFeatures/wysokie_runo.svg';

import {
    ABSORB_WATER,
    ANIMAL_FRIENDLY,
    ANTISLIP,
    CARE_AND_FAIR,
    EASY_CARE,
    EKO,
    FLOOR_HEATING,
    GLOBAL_RECYCLED,
    HANDWOVEN,
    HIGH_FLEECE,
    LOW_FLEECE,
    MACHINE_WASHABLE,
    MACHINE_WOVEN,
    OEKO,
    OPTIMUM_FLEECE_HEIGHT,
    SUITABLE_FOR_ALLERGY_SUFFERS,
    WEATHERPROOF,
    WOOL_100,
} from './ProductKeyFeatures.config';

import './ProductKeyFeatures.style';

/** @namespace Satisfly/Component/ProductKeyFeatures/Component/ProductKeyFeatures */
export const ProductKeyFeatures = ({ features }) => {
    if (features.length === 0) {
        return null;
    }

    const getIcon = (item) => {
        switch (item.value) {
        case ANTISLIP:
            return <AntiSlipIcon />;
        case CARE_AND_FAIR:
            return <img src={ CareAndFairIcon } block="ProductKeyFeatures" elem="BiggerIcon" />;
        case EASY_CARE:
            return <EasyCareIcon />;
        case MACHINE_WASHABLE:
            return <MachineWashableIcon />;
        case OEKO:
            return <img src={ OEKOIcon } />;
        case ABSORB_WATER:
            return <AbsorbWaterIcon />;
        case GLOBAL_RECYCLED:
            // icon in this form doesn't accept block/elem/mods, it has to be className
            return <RecycleIcon className="ProductKeyFeatures-BiggerIcon" />;
        case LOW_FLEECE:
            return <LowFleeceIcon />;
        case WEATHERPROOF:
            return <OutdoorIcon />;
        case SUITABLE_FOR_ALLERGY_SUFFERS:
            return <AlergicIcon />;
        case EKO:
            return <EkoIcon />;
        case ANIMAL_FRIENDLY:
            return <AnimalIcon />;
        case OPTIMUM_FLEECE_HEIGHT:
            return <FleeceIcon />;
        case MACHINE_WOVEN:
            return <MachineIcon />;
        case HANDWOVEN:
            return <HandIcon />;
        case HIGH_FLEECE:
            return <HighFleeceIcon />;
        case WOOL_100:
            return <WoolIcon />;
        case FLOOR_HEATING:
            return <FloorHeatingIcon />;
        default:
            return null;
        }
    };

    return (
        <ContentWrapper
          label="Product reviews"
          mix={ { block: 'ProductKeyFeatures' } }
          wrapperMix={ { block: 'ProductKeyFeatures', elem: 'Wrapper' } }
        >
            <ExpandableContent
              mix={ { block: 'ProductKeyFeatures', elem: 'Content' } }
              heading={ __('Key features') }
              expandable
              isContentExpanded
            >
                <div block="ProductKeyFeatures" elem="Grid">
                    { features.map((item) => (
                        <div block="ProductKeyFeatures" elem="GridItem">
                            <div block="ProductKeyFeatures" elem="Icon">
                                { getIcon(item) }
                            </div>
                            { item.label }
                        </div>
                    )) }
                </div>
            </ExpandableContent>
        </ContentWrapper>
    );
};

ProductKeyFeatures.propTypes = {
    features: PropTypes.array.isRequired,
};

export default ProductKeyFeatures;
