/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const OEKO = '5630';
export const MACHINE_WASHABLE = '8764';
export const ANTISLIP = '15192';
export const EASY_CARE = '15195';
export const CARE_AND_FAIR = '15193';
export const GLOBAL_RECYCLED = '15194';
export const ABSORB_WATER = '8994';
export const LOW_FLEECE = '15196';
export const WEATHERPROOF = '15197';
export const SUITABLE_FOR_ALLERGY_SUFFERS = '15198';
export const EKO = '15199';
export const ANIMAL_FRIENDLY = '15200';
export const OPTIMUM_FLEECE_HEIGHT = '15201';
export const MACHINE_WOVEN = '15202';
export const HANDWOVEN = '15203';
export const HIGH_FLEECE = '15204';
export const WOOL_100 = '15191';
export const FLOOR_HEATING = '5631';
