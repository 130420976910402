/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import { ExpandableContentComponent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.style';

/** @namespace Satisfly/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        expandable: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        expandable: false,
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile }, expandable } = this.props;

        if (!isMobile && !expandable) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderContent() {
        const { children, mix, expandable } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, expandable };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }
}

export default ExpandableContentComponent;
