/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import VideoPlayer from 'Component/VideoPlayer';
import { VideoThumbnailComponent as SourceVideoThumbnail } from 'SourceComponent/VideoThumbnail/VideoThumbnail.component';

import './VideoThumbnail.style';

/**
 * VideoThumbnail component
 * @class VideoThumbnail
 * @namespace Satisfly/Component/VideoThumbnail/Component */
export class VideoThumbnailComponent extends SourceVideoThumbnail {
    static propTypes = {
        ...super.propTypes,
        isVideoZoomed: PropTypes.func.isRequired,
        isPopup: PropTypes.bool.isRequired,
        activeImage: PropTypes.number.isRequired,
        itemIndex: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isMainGallery: PropTypes.bool,
        handleClickPlayIcon: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isMainGallery: false,
    };

    _renderYoutubeVideo(videoId) {
        const YouTube = this.youTubeComponent;

        if (!YouTube) {
            return null;
        }

        return (
            <YouTube
              videoId={ videoId }
              containerClassName="VideoPopup-YouTubeContainer"
              // eslint-disable-next-line react/forbid-component-props
              className="VideoPopup-YouTube"
              opts={ { playerVars: { autoplay: 1, rel: 0 } } }
            />
        );
    }

    renderPopupVideo() {
        const {
            media,
            activeImage,
            itemIndex,
        } = this.props;

        if (activeImage !== itemIndex) {
            return null;
        }

        return (
            <div block="VideoThumbnail">
                <VideoPlayer media={ media } activeImage={ activeImage } itemIndex={ itemIndex } />
            </div>
        );
    }

    renderPlayIcon() {
        const { handleClickPlayIcon, isMainGallery, isMobile } = this.props;

        if (isMobile) {
            return (
                <>
                    <span block="VideoThumbnail" elem="PlayIconWrapper">
                        <span block="VideoThumbnail" elem="PlayIcon" onClick={ handleClickPlayIcon } />
                    </span>
                    <span block="VideoThumbnail" elem="PauseIcon" onClick={ handleClickPlayIcon } />
                </>
            );
        }

        if (isMainGallery) {
            return (
                <>
                    <span block="VideoThumbnail" elem="PlayIcon" onClick={ handleClickPlayIcon }>
                        { __('Play video') }
                    </span>
                    <span block="VideoThumbnail" elem="PauseIcon" onClick={ handleClickPlayIcon } />
                </>
            );
        }

        return (
            <span block="VideoThumbnail" elem="PlayIcon" onClick={ handleClickPlayIcon }>
                { __('Play video') }
            </span>
        );
    }

    render() {
        const {
            media: {
                large: { url },
                video_content: { video_title },
            },
            onPlayClick,
            isVideoZoomed,
            isPopup,
            isMobile,
            videoRef,
        } = this.props;

        if (isPopup && !isMobile) {
            return this.renderPopupVideo();
        }

        return (
            <div block="VideoThumbnail">
                <button
                  block="VideoThumbnail"
                  elem="Button"
                  onClick={ onPlayClick }
                  title={ __('Play video %s', video_title) }
                  mods={ { isVideoZoomed } }
                >
                    <video muted block="VideoPlayer" elem="Video" playsInline preload="metadata" ref={ videoRef }>
                        <source
                          src={ `${url}#t=0.001` }
                          type="video/mp4"
                        />
                        <source
                          src={ `${url}#t=0.001` }
                          type="video/webm"
                        />
                        <source
                          src={ `${url}#t=0.001` }
                          type="video/avi"
                        />
                        <source
                          src={ `${url}#t=0.001` }
                          type="video/wmv"
                        />
                        <source
                          src={ `${url}#t=0.001` }
                          type="video/mov"
                        />
                    </video>
                    { this.renderPlayIcon() }
                </button>
            </div>
        );
    }
}

export default VideoThumbnailComponent;
