Mosaic.addPlugins([require('/var/www/public/app/design/storefront/satisfly/node_modules/@satisfly/product-labels/src/plugin/ProductActions.plugin.js')]);
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import ImageZoomPopup from 'Component/ImageZoomPopup';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { VIMEO_FORMAT, YOUTUBE_FORMAT } from 'Component/VideoThumbnail/VideoThumbnail.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';

import ProductGallery from './ProductGallery.component';
import {
    AMOUNT_OF_PLACEHOLDERS,
    ImageType,
    MediaType,
    PRODUCT_GALLERY_POPUP_ID,
    ZOOM_ANIMATION_TIME,
} from './ProductGallery.config';

/** @namespace Satisfly/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceContainer {
    getGalleryPictures() {
        const {
            areDetailsLoaded,
            product: {
                media_gallery_entries: mediaGallery = [],
                [ImageType.SMALL]: { url: thumbnailUrl } = {},
                [ImageType.IMAGE]: { url: imageTypeUrl } = {},
                name,
                attributes: { url_video = null } = {},
            },
        } = this.props;

        const url = imageTypeUrl || thumbnailUrl;

        let finallGallery = mediaGallery;

        if (finallGallery.length) {
            finallGallery = mediaGallery
                .filter(({ disabled, media_type }) => !disabled && media_type !== MediaType.VIDEO_TYPE)
                .sort((a, b) => a.position - b.position);
        }

        const [, vimeoId] = VIMEO_FORMAT.exec(url_video?.attribute_value || '') || [];
        const [, youtubeId] = YOUTUBE_FORMAT.exec(url_video?.attribute_value || '') || [];

        if (url_video?.attribute_value && !vimeoId && !youtubeId) {
            const tempGallery = finallGallery;

            finallGallery = [
                finallGallery?.[0],
                {
                    id: url_video.attribute_id,
                    file: '',
                    label: null,
                    position: finallGallery.length + 1,
                    disabled: false,
                    media_type: 'external-video',
                    types: [],
                    video_content: {
                        media_type: 'external-video',
                        video_description: 'Video.',
                        video_metadata: '',
                        video_provider: '',
                        video_title: '',
                        video_url: url_video?.attribute_value,
                    },
                    thumbnail: {
                        url: url_video?.attribute_value,
                    },
                    base: {
                        url: url_video?.attribute_value,
                    },
                    large: {
                        url: url_video?.attribute_value,
                    },
                },
                ...tempGallery.slice(1),
            ];
        }

        if (finallGallery.length) {
            return finallGallery;
        }

        if (!url) {
            return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
        }

        const placeholders = !areDetailsLoaded
            ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

        return [
            {
                thumbnail: { url },
                base: { url },
                id: ImageType.SMALL,
                label: name,
                media_type: ImageType.IMAGE,
            },
            ...placeholders,
        ];
    }

    containerProps() {
        const { activeImage, isZoomEnabled, isImageZoomPopupActive } = this.state;
        const {
            product: { id },
            isMobile,
            isWithEmptySwitcher,
            showLoader,
        } = this.props;

        return {
            gallery: this.getGalleryPictures(),
            productName: this._getProductName(),
            activeImage,
            isZoomEnabled,
            productId: id,
            isMobile,
            isImageZoomPopupActive,
            sliderRef: this.sliderRef,
            isWithEmptySwitcher,
            showLoader,
        };
    }

    handleZoomChange(args) {
        const { isZoomEnabled } = this.state;

        setTimeout(() => {
            if (args.scale !== 1) {
                if (isZoomEnabled) {
                    return;
                }

                document.documentElement.classList.add('overscrollPrevented');
                this.setState({ isZoomEnabled: true });
            }
        }, ZOOM_ANIMATION_TIME);
    }

    handleImageZoomPopupActiveChange(isImageZoomPopupActive = false) {
        this.setState({ isImageZoomPopupActive });
    }

    render() {
        const { isImageZoomPopupActive, activeImage } = this.state;

        return (
            <ImageZoomPopup
              isActive={ isImageZoomPopupActive }
              onClose={ this.handleImageZoomPopupClose }
              activeImageId={ activeImage }
              popupId={ PRODUCT_GALLERY_POPUP_ID }
            >
                <Subscribe to={ [SharedTransitionContainer] }>
                    { ({ registerSharedElementDestination }) => (
                        <ProductGallery
                          registerSharedElementDestination={ registerSharedElementDestination }
                          { ...this.containerProps() }
                          { ...this.containerFunctions }
                        />
                    ) }
                </Subscribe>
            </ImageZoomPopup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
