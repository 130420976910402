/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const MAX_ZOOM_SCALE = 8;
export const ZOOM_ANIMATION_TIME = 200;
export const ZOOM_STEP = 75;

export const IMAGE_TYPE = 'image';
export const VIDEO_TYPE = 'external-video';
export const PLACEHOLDER_TYPE = 'placeholder';

export const THUMBNAIL_KEY = 'small_image';
export const AMOUNT_OF_PLACEHOLDERS = 3;

export const PRODUCT_GALLERY_POPUP_ID = 'ProductGalleryPopup';

export const MAX_FILE_NAME_LENGTH = 15;
